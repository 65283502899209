import styled from "styled-components"

export const Container = styled.div`
  ${( { containerClass }) => containerClass ? containerClass : `
    display: flex;
    flex-direction: column;
  `}
  
  span {
    color: ${({ theme }) => theme.colors.auxiliary.alert};
  }

  .fill {
    border-color: ${({ theme }) => theme.colors.secondary.health_green};
  }

  .disabled {
    background-color: ${({ theme }) => theme.colors.gray.disable};
    border: 1px solid ${({ theme }) => theme.colors.text.disable};
    cursor: not-allowed;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.text.disable};
    }
  }

  .error {
    border-color: ${({ theme }) => theme.colors.auxiliary.alert};
  }

  .error-alert {
    display: inline-block;
    margin-top: ${({ theme }) => theme.spacing["2xs"]};
  }
`

export const Label = styled.label`
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
`

export const InputContainer = styled.div`
  width: ${(props) => props.width ? props.width : '100%'};
  height: 3rem;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.gray.dark};
  padding: 0 0.875rem;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray.dark};

  :has(input:focus) {
    border: 2px solid ${({ theme }) => theme.colors.green["900"]};
  }

  div {
    display: flex;
    gap: 0.5rem;
  }
`

export const Input = styled.input`
  width: 100%;
  background: transparent;
  border: 0;
  padding-right: 0.5rem;

  :focus {
    outline: 0;
  }

  &::placeholder {
    color: ${({theme}) => theme.colors.gray.disable} !important;
    font-size: 1rem !important;
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.bold};
  }
`

export const Example = styled.div `
  margin-top: ${({theme}) => theme.spacing['2xs']};

  .text {
    color: ${({theme}) => theme.colors.gray.dark};
    font-size: 0.8125rem;
    font-weight: ${({theme}) => theme.fontWeight.fontWeight.fontWeightMedium};
    font-style: italic;
  }

  .subtext {
    color: ${({theme}) => theme.colors.secondary.health_green};
  }
`
