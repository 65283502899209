import React, { useState } from "react"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import CancelIcon from "@mui/icons-material/Cancel"

import { Container, Label, Input, InputContainer, Example } from "./styles"
import colors from "../../../../styles/tokens/colors"
import { useRandomIds } from "../../../../functions/useRandomIds"

export function TextInput({
  label,
  placeholder,
  value,
  error,
  disabled,
  isPassword,
  large = false,
  className,
  example,
  width,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(isPassword ? false : true)
  const id = useRandomIds()
  const inputId = props.id || id("input")

  return (
    <Container containerClass={props.containerClass}>
      <Label htmlFor={inputId}>{label}</Label>
      <InputContainer
        className={[value && "fill", error && "error", disabled && "disabled", className]}
        large={large}
        width={width}
      >
        <Input
          disabled={disabled}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          id={inputId}
          aria-invalid={error ? true : false}
          {...props}
        />
        <div role="button" aria-hidden="true">
          {error && <CancelIcon style={{ color: colors.error.main }} />}
          {isPassword && (
            <>
              {showPassword ? (
                <VisibilityIcon onClick={() => setShowPassword(false)} />
              ) : (
                <VisibilityOffIcon onClick={() => setShowPassword(true)} />
              )}
            </>
          )}
        </div>
      </InputContainer>
      {example && <Example><span className="text">{example}</span></Example>}
      {error && <span className="error-alert">{error}</span>}
    </Container>
  )
}
