import React from "react"
import styled from "styled-components"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import PessoasImg from "../../assets/images/pessoas.svg"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import { TextInput } from "../../components/profissionaisSaude/Inputs/InputText"

const Content = styled.section`
  width: 100%;
  max-width: 68.75rem;
  min-height: calc(100vh - 13.25rem);
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 0 5.125rem;
  gap: 1.375rem;

  @media screen and (max-width: 1020px) {
    padding: 1rem 2.5rem;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`

const ContentInfos = styled.div`
  max-width: 445px;

  fieldset{
      display:flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 1rem;
      margin-bottom: 1rem;

      input::placeholder{
          font-size: 1rem;
          font-weight: 400;
          color:#515151;
      }

      span{
          line-height: 1.125rem;
          font-size: 0.75rem;
          font-weight: 400;
          color:#515151;
          a{
              color: #018762;
          }
      }
  }

`

const ImageWrapper = styled.div`
  position: relative;
  max-width: 516px;
  max-height: 427px;
`

const Image = styled(PessoasImg)`
  max-width: 516px;
  max-height: 427px;
  position: relative;
`

const ImageCredit = styled.span`
  color: #515151;
  font-size: 0.75rem;
  position: absolute;
  bottom: 0;
  right: 5rem;
`

const ImageCreditLink = styled.a`
  text-decoration: underline;
`

const Heading = styled.div`
  width: 100%;
  color: #1f1f1f;
  font-family: "Nunito";
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const Text = styled.p`
  font-family: "Nunito";
  font-size: .875rem;
  color: #1f1f1f;
  
  
`
const BoxText = styled.div`
  display: flex ;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
`
export default function CadastrarLocalidadeIndisponivel() {
  return (
    <>
      <Header />
      <Content>
        <ContentInfos>
            <Heading>Chegaremos em breve na sua região!</Heading>
            <BoxText>
                <Text>
                    Agradecemos seu interesse em atuar como profissional da Lacrei Saúde.
                </Text>
                <Text> 
                    Ainda não estamos disponíveis na sua região, mas podemos te avisar assim que chegarmos em seu Estado! É só nos informar a sua profissão e seu e-mail nos campos indicados. 
                </Text>
            </BoxText>
            <form >
                <fieldset>
                    <TextInput    
                        type="text"  
                        label="Profissão" 
                        placeholder="Informe sua profissão"
                    />
                    <TextInput 
                      type="text"  
                      label="E-mail" 
                      placeholder="Digite seu E-mail"
                    />
                    <TextInput 
                      type="text" 
                      label="Estado" 
                      placeholder="Digite seu estado"
                    />
                    <span>
                        Ao clicar em ”Enviar” você aceita receber nossas comunicações por e-mail de acordo com a <a href="#">Política de Privacidade</a>.
                    </span>
                </fieldset>

                <Button link="/saude/localidade-indisponivel-confirma" title="Enviar" />
            </form>
        </ContentInfos>
        <ImageWrapper>
          <Image />
          <ImageCredit>
            Ilustrado por{" "}
            <ImageCreditLink target={"_blank"} href="https://br.freepik.com/">
              Freepik
            </ImageCreditLink>
          </ImageCredit>
        </ImageWrapper>
      </Content>
      <Footer hasButtonUp/>
    </>
  )
}